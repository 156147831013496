/* Tried defining these CSS properties in scss modules, but D3 doesn't have access to the generated hashed classnames */
/* Therefore, we must use a vanilla CSS file for D3 to reference while it dynamically generates SVG and html */
/* Furthermore, this vanilla CSS file must be included in the root of the application (in index.tsx) */

/* ----------------------------------- */
/* Base */
/* ----------------------------------- */

*, ::after, ::before {
  box-sizing: border-box;
}

body {
  background-color: rgb(32, 32, 32);
  color: rgb(250, 250, 250);
  font-family: 'Lato', sans-serif;
  margin: 0;
}

h2,
a,
p {
  color: rgb(200, 200, 200);
}

/* ----------------------------------- */
/* D3 Graph */
/* ----------------------------------- */

.bar {
  cursor: pointer;
  fill: white;
}

/* ----------------------------------- */
/* D3 Tooltip */
/* ----------------------------------- */

.tooltip {
  position: absolute;
  pointer-events: none;
  color: white;
  background-color: #343b3f;
  opacity: 0; /* Set inital opacity to 0 to hide tooltip upon D3 graph generation*/
  padding: 2rem;
}

/* ----------------------------------- */
/* D3 Card */
/* ----------------------------------- */

.card {
  background-color: rgb(48, 48, 48);
  color: white;
  margin: .5rem;
  flex: 1 1 auto;
  min-height: 15rem;
  padding: 1.25rem;
}

@media only screen and (max-width: 991.98px) {
  .card {
    min-height: 17rem;
  }
}

@media only screen and (max-width: 767.98px) {
  .card {
    min-height: 10rem;
  }
}

.card-sizing {
  width: 18rem;
}

@media only screen and (max-width: 991.98px) {
  .card-sizing {
    width: 14rem;
  }
}

@media only screen and (max-width: 767.98px) {
  .card-sizing {
    width: 24rem;
  }
}

@media only screen and (max-width: 575.98px) {
  .card-sizing {
    width: auto;
  }
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: .25rem;
}

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  border: 1px solid rgba(0,0,0,.125);
  background-color: rgb(38, 38, 38);
  color: white;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 1px;
}